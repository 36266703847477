body {
  margin: 30px auto 0px auto;
  color: #FFFFFF;
  font-family: serif;
}

@media (prefers-reduced-motion) {
  body {
    background: #512749;
  }

  .animated {
    visibility: hidden;
  }
}

@media (prefers-reduced-motion: no-preference) {
  body {
    background-image: url('/assets/images/stars-background.gif');
    background-repeat: repeat;
  }
}

.contents {
  max-width: 650px;
  margin: 0 auto;
  padding-bottom: 20px;
}

a {
  color: #7955fc;
}

h2 {
  text-align: center;
}

.header {
  text-align: center;
}

.footer {
  text-align: center;
}

#welcome {
  max-width: 300;
  text-align: center;
  align: center;
}

#footnote {
  font-size: 14px;
  text-align: center;
}
